@font-face {
	font-family: 'digital';
	src: url('../fonts/digital.ttf');
}

.test {
	background-color: #ff0000;
	display: block !important;
	padding: 4px;
	position: absolute;
}

.digitalWatch {
	background-color: #000;
	background-image: url('../img/dot.png');
	border: 5px solid #575757;
	box-shadow: 0 2px 9px 4px rgba(0, 0, 0, 0.4);
	text-shadow: 0 0 5px #ff0000cf, 0 0 20px #ff0000e0;
	border-radius: 10px;
	color: #ff0000;
	padding: 5px;
	font-family: 'digital';
	font-size: 50px;
	letter-spacing: 5px;
}

.digitalWatch_timer {
	min-width: 269px;
}

@media (max-width: 414.98px) {
	.digitalWatch {
		font-size: 23px;
		letter-spacing: 2px;
	}
	.digitalWatch_timer {
		min-width: 121px;
	}
}

@media (min-width: 415px) and (max-width: 575.98px) {
	.digitalWatch {
		font-size: 38px;
	}
	.digitalWatch_timer {
		min-width: 220px;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.digitalWatch {
		font-size: 45px;
	}
	.digitalWatch_timer {
		min-width: 248px;
	}
}

@media (min-width: 768px) and (max-width: 1199.98px) {
	.digitalWatch {
		font-size: 36px;
	}
	.digitalWatch_timer {
		min-width: 209px;
	}
}
